header {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1001;
  .header-contents{
    display: flex;
    justify-content: space-around;
    font-size: 2rem;
    font-weight: bold;
    background: #f6f5f0;
    padding: 0.5rem 1rem;
    @include md{
      font-size: 1.5rem;
      padding: 0.25rem 0.5rem;
    }
    a{
      color: #7c7c7c;
      text-decoration: none;
      margin: 0 1rem;
      @include md{
        margin: 0 0.5rem;
      }
    }
  }
}
